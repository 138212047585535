import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ViewEmployee.css';

const ViewEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}employee/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setEmployee(data.data);
        } else {
          console.error('Error fetching employee:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching employee:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployee();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return <div>Employee not found</div>;
  }

  return (
    <div className="view-container">
      <h2>Employee Details</h2>
      <div className="employee-info">
        <div className="info-item">
          <strong>Employee ID:</strong> <span>{employee.employeeId}</span>
        </div>
        <div className="info-item">
          <strong>Name:</strong> <span>{employee.name}</span>
        </div>
        <div className="info-item">
          <strong>Designation:</strong> <span>{employee.designation}</span>
        </div>
        <div className="info-item">
          <strong>Email:</strong> <span>{employee.email}</span>
        </div>
        <div className="info-item">
          <strong>Phone:</strong> <span>{employee.phoneNumber}</span>
        </div>
        <div className="info-item">
          <strong>Address:</strong> <span>{employee.dateOfJoining}</span>
        </div>
        <div className="info-item">
          <strong>Salary:</strong> <span>{employee.salary}</span>
        </div>
      </div>
      <button className="back-button" onClick={() => navigate('/users')}>
        Back to Team Members
      </button>
    </div>
  );
};

export default ViewEmployee;
