import './index.css'
const DashboardCard = ({ title, imgSrc, className }) => {
  return (
    <div className={`dashboard-card-main-container ${className}`}>
      <div className="dashboard-card-content">
        <div className="dashboard-card-img-container">
          <img src={imgSrc} alt={title} className="dashboard-card-img" />
        </div>
        <p className="dashboard-card-title">{title}</p>
      </div>
    </div>
  );
};
export default DashboardCard