import React, { useState, useEffect } from 'react';
import './ViewInvoice.css';
import { useParams, useNavigate } from 'react-router-dom';

const ViewInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setInvoice(data.data);
        } else {
          console.error('Error fetching invoice:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!invoice) {
    return <div>Invoice not found</div>;
  }

  return (
    <div className="invoice-container">
      <h2>Invoice Details</h2>
      <div className="invoice-details">
        <div className="detail-group">
          <label>Invoice Id</label>
          <div className="detail-value">{invoice.invoicenumber}</div>
        </div>
        <div className="detail-group">
          <label>Customer Name</label>
          <div className="detail-value">{invoice.venderName}</div>
        </div>
        <div className="detail-group">
          <label>Date</label>
          <div className="detail-value">{invoice.date}</div>
        </div>
       
      </div>
      <button
        className="back-button"
        onClick={() => navigate('/invoices')}
      >
        Back to Invoices
      </button>
    </div>
  );
};

export default ViewInvoice;
