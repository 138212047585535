import React, { useState, useEffect } from 'react';
import './EditEmployee.css';
import { useParams, useNavigate } from 'react-router-dom';

const EditEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editMode, setEditMode] = useState({
    name: false,
    designation: false,
    email: false,
  });
  const [formData, setFormData] = useState({
    employeeId: '',
    name: '',
    designation: '',
    email: '',
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}employee/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setEmployee(data.data);
          setFormData({
            employeeId: data.data.employeeId,
            name: data.data.name,
            designation: data.data.designation,
            email: data.data.email,
          });
        } else {
          console.error('Error fetching employee:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching employee:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}employee/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsUpdating(false);
        navigate('/users');
      } else {
        console.error('Error updating employee:', response.statusText);
        setIsUpdating(false);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      setIsUpdating(false);
    }
  };

  const handleEditClick = (field) => {
    setEditMode((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return <div>Employee not found</div>;
  }

  return (
    <div className="edit-container">
      <h2>Edit Employee</h2>
      <form onSubmit={handleUpdate}>
        <div className="form-group">
          <label>Employee Id</label>
          <input
            type="text"
            name="employeeId"
            value={formData.employeeId}
            onChange={handleChange}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            readOnly={!editMode.name}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('name')}></i>
        </div>
        <div className="form-group">
          <label>Designation</label>
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            readOnly={!editMode.designation}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('designation')}></i>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            readOnly={!editMode.email}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('email')}></i>
        </div>
        <button type="submit" className="update-button" disabled={isUpdating}>
          {isUpdating ? 'Updating...' : 'Update'}
        </button>
      </form>
      {isUpdating && <div className="loader"></div>}
    </div>
  );
};

export default EditEmployee;
