import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import logo from "../../Images/company-logo.png";
import "./viewpayslip.css";
import Toast from "../../utils/toast";

const PayslipView = () => {
  const { id } = useParams();
  const [payslip, setPayslip] = useState(null);

  useEffect(() => {
    const fetchPayslip = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}payslip/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setPayslip(data.data);
        } else {
          console.error('Error fetching payslip:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching payslip:', error);
      }
    };

    fetchPayslip();
  }, [id]);

  // Ensure that the data is loaded before rendering
  if (!payslip) {
    return <div>Loading...</div>;
  }

  return (
    <form>
      <div className="payslip-container">
        <div id="payslip" className="pdf-container">
          <div className="company-details">
            <img src={logo} alt="Company Logo" className="company-logo" />
            <div className="company-text">
              <h1>Labyrinth Global Solutions India Private Limited</h1>
              <h2>Payslip for the month of {payslip.month} {payslip.year}</h2>
              <h3>Financial Year 2023-24</h3>
            </div>
          </div>
          <div className="payslip-info">
            <h3
              style={{
                textAlign: "end",
                fontWeight: "normal",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
              }}
            >
              Private & Confidential
            </h3>
            <table className="info-table pdf-info-table">
              <thead>
                <tr>
                  <th colSpan="4">Associate Information</th>
                </tr>
                <tr>
                  <th
                    style={{ textAlign: "left", backgroundColor: "#ffff" }}
                    colSpan={4}
                  >
                    {payslip.name}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Associate Id</td>
                  <td>{payslip.employeeId}</td>
                  <td>Location</td>
                  <td>{payslip.location}</td>
                </tr>
                <tr>
                  <td>Designation</td>
                  <td>{payslip.designation}</td>
                  <td>Pay of Period</td>
                  <td>{payslip.payofPeriod}</td>
                </tr>
                <tr>
                  <td>PAN</td>
                  <td>{payslip.pan}</td>
                  <td>Date of Joining</td>
                  <td>{payslip.dateOfJoining}</td>
                </tr>
                <tr>
                  <td>Bank Account No</td>
                  <td>{payslip.bankAccount}</td>
                  <td>Monthly Salary</td>
                  <td>{payslip.monthlySalary}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>{payslip.gender}</td>
                  <td>Working Days</td>
                  <td>{payslip.workingDays}</td>
                </tr>
                <tr>
                  <td>UAN</td>
                  <td>{payslip.uan}</td>
                  <td>Paid Leaves</td>
                  <td>{payslip.paidLeaves}</td>
                </tr>
                <tr>
                  <td>PF Account No</td>
                  <td>{payslip.pfAccount}</td>
                  <td>Loss of Pay Days</td>
                  <td>{payslip.lossOfPayDays}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="payslip-earnings-deductions">
            <table className="earnings-deductions-table pdf-earnings-deductions-table">
              <thead>
                <tr>
                  <th>Earnings</th>
                  <th>Amount</th>
                  <th>Deductions</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>{payslip.basic}</td>
                  <td>PF</td>
                  <td>{payslip.pfEmployeeContribution}</td>
                </tr>
                <tr>
                  <td>HRA</td>
                  <td>{payslip.houseRentAllowance}</td>
                  <td>TDS</td>
                  <td>{payslip.tds}</td>
                </tr>
                <tr>
                  <td>Conveyance Allowance</td>
                  <td>{payslip.conveyanceAllowance}</td>
                  <td>Advance</td>
                  <td>{payslip.advanceStatutoryBonus}</td>
                </tr>
                <tr>
                  <td>Transport Allowance</td>
                  <td>{payslip.transportAllowance}</td>
                  <td>Loss of Pay Days Deductions</td>
                  <td>{payslip.lossOfPay}</td>
                </tr>
                <tr>
                  <td>Bonus</td>
                  <td>{payslip.bonus}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Special Allowance</td>
                  <td>{payslip.specialAllowance}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>(A) Total Earnings</b>
                  </td>
                  <td>{parseFloat(payslip.totalEarnings).toFixed(2)}</td>
                  <td>
                    <b>(B) Total Deduction</b>
                  </td>
                  <td>{parseFloat(payslip.totalDeductions).toFixed(2)}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "end",
                      marginRight: "20px",
                      paddingRight: "25px",
                    }}
                    colSpan="3"
                  >
                    <b>Net Salary = (A)-(B)</b>
                  </td>
                  <td>{parseFloat(payslip.netSalary).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p
              style={{ color: "#808080", fontSize: "13px", marginTop: "-12px" }}
            >
              Note: This is a computer-generated payslip. No signature is required.
            </p>
          </div>
          <div className="company-address">
            <hr
              style={{ width: "100%", borderColor: "black", marginTop: "20px" }}
            />
            <p>16-2-664/1, GOVT PRESS ROAD, MALAKPET, HYDERABAD, TELANGANA-500024</p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PayslipView;
