import { useState } from "react";
import "./index.css";

import { PiUserListFill } from "react-icons/pi";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";

import AdminNavbar from "../AdminNavbar";

import Cookies from "js-cookie";
import { IoLogOut } from "react-icons/io5";

import Logo from "../../Images/company-logo.png";

const AdminSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const role = Cookies.get("role");

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("jwt");
    navigate("/login");
  };

  const handleMouseEnter = () => {
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    setIsCollapsed(true);
  };

  return (
    <div className="adminpanel-main-container ">
      <div style={{ display: "flex", minHeight: "100vh", width: "100%" }}>
        <Sidebar
          backgroundColor="#fff"
          //   background="linear-gradient(135deg, #411575, #cd2dd6)"
          className="h-screen no-scrollbar"
          collapsed={isCollapsed}
          collapsedWidth="65px"
        >
          <Menu
            className="sidebar-icons-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            menuItemStyles={{
              button: {
                backgroundColor: "#fff",
                // background: "linear-gradient(135deg, #411575, #cd2dd6)",
                color: "#000",

                margin: "0px !important",

                "&:active": {
                  backgroundColor: "#ED6C34",
                  color: "#b6c8d9",
                },
                "&:hover": {
                  width: "90%",
                  marginLeft: "5px",
                  borderRadius: "10px",
                  backgroundColor: "#411575", // Background color on hover
                  color: "#fff ", // Text color on hover
                },
              },
            }}
          >
            <div className="hamburger-icon">
              <div
                className={`w-16 h-10 flex items-center ${
                  isCollapsed ? "hidden" : "flex"
                }`}
              >
                <img src={Logo} alt="" className="w-full" />
              </div>
              <GiHamburgerMenu
                color="#000"
                onClick={() => setIsCollapsed(!isCollapsed)}
                className={`${isCollapsed ? `ml-3` : ""}`}
              />
            </div>

            <MenuItem
              className="admin-sidebar-menu-item"
              icon={<PiUserListFill fontSize="20px" />}
              component={<Link to="/users" />}
              onClick={() => setActiveTab(1)}
            >
              Employees
            </MenuItem>
            <MenuItem
              className="admin-sidebar-menu-item"
              icon={<PiUserListFill fontSize="20px" />}
              component={<Link to="/payslips" />}
              onClick={() => setActiveTab(1)}
            >
              Payslips
            </MenuItem>
            <MenuItem
              className="admin-sidebar-menu-item"
              icon={<PiUserListFill fontSize="20px" />}
              component={<Link to="/po" />}
              onClick={() => setActiveTab(1)}
            >
              PO
            </MenuItem>
            <MenuItem
              className="admin-sidebar-menu-item"
              icon={<PiUserListFill fontSize="20px" />}
              component={<Link to="/invoices" />}
              onClick={() => setActiveTab(1)}
            >
              Invoice
            </MenuItem>

            <MenuItem
              className="admin-sidebar-menu-item"
              icon={<IoLogOut fontSize="20px" />}
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </Menu>
        </Sidebar>
        <div className="admin-navbar-outlet">
          <AdminNavbar />
          <div className="w-full p-2 bg-gray-50">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
