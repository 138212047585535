import React from "react";
import ReactDOM from "react-dom/client";
import EmployeeForm from "./components/Payroll/EmployeeForm";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Invoice from "./components/Invoices/Invoice";
import EditEmployee from "./components/Members/EditEmployee";
import TeamMembers from "./components/Members/TeamMembers";
import ViewEmployee from "./components/Members/ViewEmployee";
import Payslip from "./components/Payroll/Payslip";
import PurchaseOrder from "./components/Po/PurchaseOrder";
import PurchaseOrders from "./components/Po/PurchaseOrders";
import AdminSidebar from "./components/Sidebar";
import Login from "./pages/Login";
import PaySlips from "./components/Payroll/PaySlips";
import CreateInvoice from "./components/Invoices/CreateInvoice";
import InvoicesList from "./components/Invoices/InvoicesList";
import ViewInvoice from "./components/Invoices/ViewInvoice";
import EditInvoice from "./components/Invoices/EditInvoice";
import PayslipView from "./components/Payroll/PayslipView";
import Protectrouter from "./components/Protectrouter";

const router = createBrowserRouter([
  {
    path: "/",
    element:<Protectrouter> <AdminSidebar /></Protectrouter>,
    children: [

      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/invoices",
        element: <InvoicesList />,
      },
      {
        path: "/users",
        element: <TeamMembers />,
      },
      {
        path:"/viewinvoice/:id",
        element:<ViewInvoice/>
      },
      {
        path: "/payslips",
        element: <PaySlips />,
      }
      ,
      {
        path: "/payslip",
        element: <TeamMembers />,
      },
      {
        path: "/po",
        element: <PurchaseOrder />,
      },
      {
        path: "/po1",
        element: <PurchaseOrders />,
      },
      {
        path: "/edit/:id",
        element: <EditEmployee />,
      },
      {
        path: "/employeeForm",
        element: <EmployeeForm />,
      },
      {
        path: "/view/:id",
        element: <ViewEmployee />,
      },
      {
        path: "/payslip/:id",
        element: <Payslip />,
      },
      {
        path: "/po",
        element: <PurchaseOrder />,
      },
      {
        path: "/po1",
        element: <PurchaseOrders />,
      },
      {
        path: "/invoice/:id",
        element: <Invoice />,
      },
      {
        path: "/createInvoice",
        element:<CreateInvoice/>
      },
      {
        path:"/viewinvoice/:id",
        element:<ViewInvoice/>
      },
      {
        path:"/editInvoice/:id",
        element:<EditInvoice/>
      },
      {
        path:"/viewpayslip/:id",
        element:<PayslipView/>
      }
      
     
    ],
  },
  {
    path: "/login",
    element: <Login />,
  }
  ,
  {
    path:"/viewinvoice/:id",
    element:<ViewInvoice/>
  },


 
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
