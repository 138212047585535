import React, { useState } from "react";
import "./CreateInvoice.css";
import { useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";

const  CreateInvoice = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    invoicenumber: "",
    date: "",
    venderAddress: "",
    venderName: "",
    venderGstNumber: "",
    productcode: "",
    qty: "",
    description: "",
    totalAmount: "",
    bankAccountNumber: "",
    bankName: "",
    bankIfscCode: "",
    accountType: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}invoice/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      setLoading(false);
      if (response.ok) {
        const data = await response.json();
        Toast.fire({ icon: "success", title: "Invoice successfully added!" });
        navigate("/invoices");
      } else {
        const data = await response.json();
        Toast.fire({ icon: "error", title: data.data });
      }
    } catch (error) {
      setLoading(false);
      Toast.fire({ icon: "error", title: "An error occurred!" });
    }
  };

  return (
    <div className="invoice-form-container">
      {loading && <div className="loader">Loading...</div>}
      <form onSubmit={handleSubmit} className="invoice-form">
        <h2 style={{ fontWeight: "bold" }}>Invoice Information</h2>
        <div className="form-row">
          <label>Invoice Number</label>
          <input
            required
            type="text"
            name="invoicenumber"
            value={formData.invoicenumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Date</label>
          <input
            required
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor Name</label>
          <input
            required
            type="text"
            name="venderName"
            value={formData.venderName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor Address</label>
          <input
            required
            type="text"
            name="venderAddress"
            value={formData.venderAddress}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Vendor GST Number</label>
          <input
            required
            type="text"
            name="venderGstNumber"
            value={formData.venderGstNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Product Code</label>
          <input
            required
            type="text"
            name="productcode"
            value={formData.productcode}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Quantity</label>
          <input
            required
            type="text"
            name="qty"
            value={formData.qty}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Description</label>
          <input
            required
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Total Amount</label>
          <input
            required
            type="text"
            name="totalAmount"
            value={formData.totalAmount}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Bank Account Number</label>
          <input
            required
            type="text"
            name="bankAccountNumber"
            value={formData.bankAccountNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Bank Name</label>
          <input
            required
            type="text"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Bank IFSC Code</label>
          <input
            required
            type="text"
            name="bankIfscCode"
            value={formData.bankIfscCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Account Type</label>
          <input
            required
            type="text"
            name="accountType"
            value={formData.accountType}
            onChange={handleChange}
          />
        </div>
        <button className="invoice-form-button" type="submit">
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateInvoice;
