import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const Protectrouter = ({ children }) => {
    const navigate = useNavigate();
    const [jwt, setJwt] = useState('');

    useEffect(() => {
        const jwt = Cookies.get('jwt');
        setJwt(jwt);
        if (!jwt) {
            navigate('/login');
        }
    }, [navigate]); // Added dependencies to avoid unnecessary rerenders.

    if (jwt) {
        return (
            <div>
                {children}
            </div>
        );
    } else {
        navigate('/login');
        return null; // Return null to avoid rendering anything during navigation.
    }
};

export default Protectrouter;
