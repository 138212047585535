import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from "../../utils/toast";
import ConfirmModal from "../Members/ConfirmModal";
import "./PaySlips.css";

const PaySlips = () => {
  const [payslips, setPayslips] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [sending, setSending] = useState({}); // Track sending status per payslip
  const [downloading, setDownloading] = useState({}); // Track downloading status per payslip
  const membersPerPage = 10;

  const { id } = useParams();

  const fetchPayslips = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}payslip/`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        setPayslips(data.data || []);
      } else {
        const data = await response.json();
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error fetching payslips:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayslips();
  }, [id]);

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = payslips.slice(indexOfFirstMember, indexOfLastMember);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(payslips.length / membersPerPage);

  const handleCreatePayslip = async (payslipId) => {
    setSending((prev) => ({ ...prev, [payslipId]: true })); // Set specific payslip as sending
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}payslip/send/${payslipId}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const data = await response.json();
        Toast.fire({ icon: "success", title: data.data });
        await fetchPayslips(); // Refetch payslips after successful send
      } else {
        const errorData = await response.json();
        Toast.fire({ icon: "error", title: errorData.data });
      }
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
    } finally {
      setSending((prev) => ({ ...prev, [payslipId]: false })); // Reset specific payslip sending status
    }
  };

  const handleDownloadPayslip = async (payslipId) => {
    setDownloading((prev) => ({ ...prev, [payslipId]: true })); // Set specific payslip as downloading
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}payslip/download/${payslipId}`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/"/g, "")
          : "payslip.pdf";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        Toast.fire({
          icon: "success",
          title: "PDF Downloaded",
        });
      } else {
        const errorData = await response.json();
        Toast.fire({ icon: "error", title: errorData.data });
      }
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
    } finally {
      setDownloading((prev) => ({ ...prev, [payslipId]: false })); // Reset specific payslip downloading status
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-container">
      <table className="p-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Employee Id</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Month</th>
            <th>Actions</th>
            <th>Send</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {currentMembers.map((payslip, index) => (
            <tr key={payslip.id}>
              <td>{indexOfFirstMember + index + 1}</td>
              <td>{payslip.employeeId}</td>
              <td>{payslip.name}</td>
              <td>{payslip.designation}</td>
              <td>{payslip.month}</td>
              <td className="actions">
                <button className="action-button delete">
                  <i className="fas fa-trash-alt"></i>
                </button>
                <Link to={`/viewpayslip/${payslip.id}`}>
                  <button className="action-button view">
                    <i className="fas fa-eye"></i>
                  </button>
                </Link>
              </td>
              <td>
                {payslip.sendEmailStatus ? (
                  <button className="p-button sent" disabled>
                    <i className="fas fa-check"></i>
                  </button>
                ) : (
                  <button
                    className="p-button"
                    onClick={() => handleCreatePayslip(payslip.id)}
                    disabled={!!sending[payslip.id]} // Disable only if specific payslip is sending
                  >
                    {sending[payslip.id] ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "Send"
                    )}
                  </button>
                )}
              </td>
              <td>
                <button
                  className="p-button"
                  onClick={() => handleDownloadPayslip(payslip.id)}
                  disabled={!!downloading[payslip.id]} // Disable only if specific payslip is downloading
                >
                  {downloading[payslip.id] ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Download"
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <ConfirmModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default PaySlips;
