import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import logo from "../../Images/company-logo.png";
import "./Payslip.css";

import { useNavigate } from "react-router-dom";
import Toast from "../../utils/toast";

const Payslip = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    employeeId: "",
    location: "",
    designation: "",
    pan: "",
    gender: "",
    bankAccount: "",
    dateOfJoining: "",
    pfAccount: "",
    uan: "",
    paidLeaves: 0,
    lossOfPayDays: 0,
    payofPeriod: "monthly",
    workingDays: 30,
    monthlySalary: 0,
    earnings: {
      basicSalary: 0,
      hra: 0,
      conveyanceAllowance: 0,
      transportAllowance: 0,
      specialAllowance: 0,
      internetAllowance: 0,
    },
    deductions: {
      pf: 0,
      tds: 0,
      advanceStatutoryBonus: 0,
      lossOfPayDaysDeductions: 0,
    },
    totalEarnings: 0,
    totalDeductions: 0,
    netSalary: 0,
    selectedMonth: "",
    selectedYear: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    getPayslipData();
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [
    formData.earnings.basicSalary,
    formData.earnings.hra,
    formData.earnings.conveyanceAllowance,
    formData.earnings.transportAllowance,
    formData.earnings.internetAllowance,
    formData.deductions.pf,
    formData.deductions.tds,
    formData.deductions.advanceStatutoryBonus,
    formData.monthlySalary,
    formData.lossOfPayDays,
  ]);

  console.log(formData);
  const getPayslipData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}payslip/employee-details/${id}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const apiData = data.data;
        setFormData({
          name: apiData.employee.name || "",
          employeeId: apiData.employee.employeeId || "",
          location: apiData.employee.location || "",
          designation: apiData.employee.designation || "",
          pan: apiData.employee.pan || "",
          gender: apiData.employee.gender || "",
          bankAccount: apiData.employee.bankAccount || "",
          payofPeriod: "monthly",
          dateOfJoining: apiData.employee.dateOfJoining
            ? new Date(apiData.employee.dateOfJoining).toLocaleDateString()
            : "",
          pfAccount: apiData.employee.pfAccount || "",
          uan: apiData.employee.uan || "",
          workingDays: 30,
          monthlySalary: apiData.earnings.monthlySalary || 0,
          earnings: {
            basicSalary: apiData.earnings.basicSalary || 0,
            hra: apiData.earnings.hra || 0,
            specialAllowance: apiData.earnings.specialAllowance || 0,
            conveyanceAllowance: 0,
            transportAllowance: 0,
            internetAllowance: 0,
          },
          deductions: {
            pf: apiData.deductions.pf || 0,

            tds: 0,
            advanceStatutoryBonus: 0,
            lossOfPayDaysDeductions: 0,
          },
          totalEarnings: apiData.earnings.monthlySalary || 0,
          totalDeductions: apiData.deductions.pf || 0,
          netSalary: apiData.netSalary || 0,
          selectedMonth: new Date().toLocaleString("default", {
            month: "long",
          }),
          selectedYear: new Date().getFullYear(),
          paidLeaves: 0,
          lossOfPayDays: 0,
        });
      } else {
        console.error("Error fetching employee:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching employee:", error);
    }
  };

  const calculateTotals = () => {
    const totalEarnings = Object.values(formData.earnings).reduce(
      (acc, value) => acc + parseFloat(value || 0),
      0
    );
    const lossOfPayDaysDeductions =
      ((formData.monthlySalary || 0) / 30) * (formData.lossOfPayDays || 0);
    const totalDeductions =
      Object.values(formData.deductions).reduce(
        (acc, value) => acc + parseFloat(value || 0),
        0
      ) + lossOfPayDaysDeductions;
    const netSalary = totalEarnings - totalDeductions;
    setFormData((prevData) => ({
      ...prevData,
      deductions: {
        ...prevData.deductions,
        lossOfPayDaysDeductions: parseFloat(lossOfPayDaysDeductions.toFixed(2)),
      },
      totalEarnings: parseFloat(totalEarnings.toFixed(2)),
      totalDeductions: parseFloat(totalDeductions.toFixed(2)),
      netSalary: parseFloat(netSalary.toFixed(2)),
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      selectedMonth: date.toLocaleString("default", { month: "long" }),
      selectedYear: date.getFullYear(),
    }));
  };

  const handleFieldChange = (field) => (event) => {
    const { value } = event.target;
    let parsedValue = value === "" ? 0 : parseFloat(value);
    if (field === "lossOfPayDays" && parsedValue < 0) {
      parsedValue = 0;
    }
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: parsedValue,
      };
      calculateTotals();
      return updatedData;
    });
  };

  const handleNestedFieldChange = (parentField, nestedField) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [parentField]: {
        ...prevData[parentField],
        [nestedField]: parseFloat(value),
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(formData);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}payslip`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          month: formData.selectedMonth,
          year: formData.selectedYear,
          id,
        }),
      });

      setLoading(false);
      if (response.ok) {
        const data = await response.json();
        Toast.fire({ icon: "success", title: "Payslip successfully generated!" });
        navigate("/users");
      } else {
        const data = await response.json();
        Toast.fire({ icon: "error", title: data.data });
      }
    } catch (error) {
      setLoading(false);
      Toast.fire({ icon: "error", title: "An error occurred!" });
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const selectedMonth = monthNames[selectedDate.getMonth()];
  const selectedYear = selectedDate.getFullYear();

  return (
    <form onSubmit={handleSubmit}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        showMonthYearPicker
        dateFormat="MMMM yyyy"
        className="date-picker"
      />
      <div className="payslip-container">
        <div id="payslip" className="pdf-container">
          <div className="company-details">
            <img src={logo} alt="Company Logo" className="company-logo" />
            <div className="company-text">
              <h1>Labyrinth Global Solutions India Private Limited</h1>
              <h2>
                Payslip for the month of {selectedMonth} {selectedYear}
              </h2>
              <h3>Financial Year 2023-24</h3>
            </div>
          </div>
          <div className="payslip-info">
            <h3
              style={{
                textAlign: "end",
                fontWeight: "normal",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
              }}
            >
              Private & Confidential
            </h3>
            <table className="info-table pdf-info-table">
              <thead>
                <tr>
                  <th colSpan="4">Associate Information</th>
                </tr>
                <tr>
                  <th
                    style={{ textAlign: "left", backgroundColor: "#ffff" }}
                    colSpan={4}
                  >
                    {" "}
                    {formData.name}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Associate Id</td>
                  <td>
                    <input type="text" value={formData.employeeId} readOnly />
                  </td>
                  <td>Location</td>
                  <td>
                    {" "}
                    <input type="text" value={formData.location} readOnly />
                  </td>
                </tr>
                <tr>
                  <td>Designation</td>
                  <td>
                    {" "}
                    <input type="text" value={formData.designation} readOnly />
                  </td>
                  <td>Pay of Period</td>
                  <td>
                    <input type="text" value={formData.payofPeriod} readOnly />
                  </td>
                </tr>
                <tr>
                  <td>PAN</td>
                  <td>
                    <input type="text" value={formData.pan} readOnly />
                  </td>
                  <td>Date of Joining</td>
                  <td>
                    <input
                      type="text"
                      value={formData.dateOfJoining}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Bank Account No</td>
                  <td>
                    <input type="text" value={formData.bankAccount} readOnly />
                  </td>
                  <td>Monthly Salary</td>
                  <td>
                    <input
                      type="number"
                      value={formData.monthlySalary}
                      readOnly
                    />
                  </td>
                </tr>{" "}
                <tr>
                  <td>Gender</td>
                  <td>
                    <input type="text" value={formData.gender} readOnly />
                  </td>
                  <td>Working Days</td>
                  <td>
                    <input
                      type="number"
                      value={formData.workingDays}
                      onChange={handleFieldChange("workingDays")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>UAN</td>
                  <td>
                    <input type="text" value={formData.uan} readOnly />
                  </td>

                  <td>Paid Leaves</td>
                  <td>
                    <input
                      type="number"
                      value={formData.paidLeaves}
                      onChange={handleFieldChange("paidLeaves")}
                      required
                    />
                  </td>
                </tr>{" "}
                <tr>
                  <td>PF Account No</td>
                  <td>
                    <input type="text" value={formData.pfAccount} readOnly />
                  </td>
                  <td>Loss of Pay Days</td>
                  <td>
                    <input
                      type="number"
                      value={formData.lossOfPayDays}
                      onChange={handleFieldChange("lossOfPayDays")}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="payslip-earnings-deductions">
            <table className="earnings-deductions-table pdf-earnings-deductions-table">
              <thead>
                <tr>
                  <th>Earnings</th>
                  <th>Amount</th>
                  <th>Deductions</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.basicSalary}
                      onChange={handleNestedFieldChange(
                        "earnings",
                        "basicSalary"
                      )}
                      required
                    />
                  </td>
                  <td>PF</td>
                  <td>
                    <input
                      type="number"
                      value={formData.deductions.pf}
                      onChange={handleNestedFieldChange("deductions", "pf")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>HRA</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.hra}
                      onChange={handleNestedFieldChange("earnings", "hra")}
                      required
                    />
                  </td>
                  <td>TDS</td>
                  <td>
                    <input
                      type="number"
                      value={formData.deductions.tds}
                      onChange={handleNestedFieldChange("deductions", "tds")}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>Conveyance Allowance</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.conveyanceAllowance}
                      onChange={handleNestedFieldChange(
                        "earnings",
                        "conveyanceAllowance"
                      )}
                      required
                    />
                  </td>
                  <td>Advance</td>
                  <td>
                    <input
                      type="number"
                      value={formData.deductions.advanceStatutoryBonus}
                      onChange={handleNestedFieldChange(
                        "deductions",
                        "advanceStatutoryBonus"
                      )}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>Transport Allowance</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.transportAllowance}
                      onChange={handleNestedFieldChange(
                        "earnings",
                        "ransportAllowance"
                      )}
                      required
                    />
                  </td>
                  <td>Loss of Pay Days Deductions</td>
                  <td>
                    <input
                      type="number"
                      value={formData.deductions.lossOfPayDaysDeductions}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Bonus</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.internetAllowance}
                      onChange={handleNestedFieldChange(
                        "earnings",
                        "internetAllowance"
                      )}
                      required
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Special Allowance</td>
                  <td>
                    <input
                      type="number"
                      value={formData.earnings.specialAllowance}
                      onChange={handleNestedFieldChange(
                        "earnings",
                        "specialAllowance"
                      )}
                      required
                    />
                  </td>

                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>(A) Total Earnings</b>
                  </td>
                  <td>{formData.totalEarnings.toFixed(2)}</td>
                  <td>
                    <b>(B) Total Deduction</b>
                  </td>
                  <td>{formData.totalDeductions.toFixed(2)}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "end",
                      marginRight: "20px",
                      paddingRight: "25px",
                    }}
                    colSpan="3"
                  >
                    <b>Net Salary = (A)-(B)</b>
                  </td>
                  <td>{formData.netSalary.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p
              style={{ color: "#808080", fontSize: "13px", marginTop: "-12px" }}
            >
              Note : This is a computer generated payslip. No signature is
              required
            </p>
          </div>

          <div className="company-address">
            <hr
              style={{ width: "100%", borderColor: "black", marginTop: "20px" }}
            />
            <p>
              16-2-664/1, GOVT PRESS ROAD, MALAKPET, HYDERABAD, TELANGANA-500024
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button type="submit" className="submit-button">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Payslip;
