import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './EditInvoice.css';

const EditInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editMode, setEditMode] = useState({
    invoicenumber: false,
    date: false,
    venderAddress: false,
    venderName: false,
    venderGstNumber: false,
    productcode: false,
    qty: false,
    description: false,
    totalAmount: false,
    bankAccountNumber: false,
    bankName: false,
    bankIfscCode: false,
    accountType: false,
  });

  const [formData, setFormData] = useState({
    invoicenumber: '',
    date: '',
    venderAddress: '',
    venderName: '',
    venderGstNumber: '',
    productcode: '',
    qty: '',
    description: '',
    totalAmount: '',
    bankAccountNumber: '',
    bankName: '',
    bankIfscCode: '',
    accountType: '',
  });

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          setInvoice(data.data);
          setFormData({
            invoicenumber: data.data.invoicenumber,
            date: data.data.date,
            venderAddress: data.data.venderAddress,
            venderName: data.data.venderName,
            venderGstNumber: data.data.venderGstNumber,
            productcode: data.data.productcode,
            qty: data.data.qty,
            description: data.data.description,
            totalAmount: data.data.totalAmount,
            bankAccountNumber: data.data.bankAccountNumber,
            bankName: data.data.bankName,
            bankIfscCode: data.data.bankIfscCode,
            accountType: data.data.accountType,
          });
        } else {
          console.error('Error fetching invoice:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}invoice/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsUpdating(false);
        navigate('/invoices');
      } else {
        console.error('Error updating invoice:', response.statusText);
        setIsUpdating(false);
      }
    } catch (error) {
      console.error('Error updating invoice:', error);
      setIsUpdating(false);
    }
  };

  const handleEditClick = (field) => {
    setEditMode((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!invoice) {
    return <div>Invoice not found</div>;
  }

  return (
    <div className="edit-container">
      <h2>Edit Invoice</h2>
      <form onSubmit={handleUpdate}>
        <div className="editform-group">
          <label>Invoice Number</label>
          <input
            type="text"
            name="invoicenumber"
            value={formData.invoicenumber}
            onChange={handleChange}
            readOnly={!editMode.invoicenumber}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('invoicenumber')}></i>
        </div>
        <div className="form-group">
          <label>Date</label>
          <input className='invoice-input'
            type="text"
            name="date"
            value={formData.date}
            onChange={handleChange}
            readOnly={!editMode.date}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('date')}></i>
        </div>
        <div className="form-group">
          <label>Vender Address</label>
          <input className='invoice-input'
            type="text"
            name="venderAddress"
            value={formData.venderAddress}
            onChange={handleChange}
            readOnly={!editMode.venderAddress}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('venderAddress')}></i>
        </div>
        <div className="form-group">
          <label>Vender Name</label>
          <input className='invoice-input'
            type="text"
            name="venderName"
            value={formData.venderName}
            onChange={handleChange}
            readOnly={!editMode.venderName}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('venderName')}></i>
        </div>
        <div className="form-group">
          <label>Vender GST Number</label>
          <input className='invoice-input'
            type="text"
            name="venderGstNumber"
            value={formData.venderGstNumber}
            onChange={handleChange}
            readOnly={!editMode.venderGstNumber}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('venderGstNumber')}></i>
        </div>
        <div className="form-group">
          <label>Product Code</label>
          <input className='invoice-input'
            type="text"
            name="productcode"
            value={formData.productcode}
            onChange={handleChange}
            readOnly={!editMode.productcode}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('productcode')}></i>
        </div>
        <div className="form-group">
          <label>Quantity</label>
          <input
            type="text" className='invoice-input'
            name="qty"
            value={formData.qty}
            onChange={handleChange}
            readOnly={!editMode.qty}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('qty')}></i>
        </div>
        <div className="form-group">
          <label>Description</label>
          <input className='invoice-input'
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            readOnly={!editMode.description}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('description')}></i>
        </div>
        <div className="form-group">
          <label>Total Amount</label>
          <input className='invoice-input'
            type="text"
            name="totalAmount"
            value={formData.totalAmount}
            onChange={handleChange}
            readOnly={!editMode.totalAmount}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('totalAmount')}></i>
        </div>
        <div className="form-group">
          <label>Bank Account Number</label>
          <input
            type="text" className='invoice-input'
            name="bankAccountNumber"
            value={formData.bankAccountNumber}
            onChange={handleChange}
            readOnly={!editMode.bankAccountNumber}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('bankAccountNumber')}></i>
        </div>
        <div className="form-group">
          <label>Bank Name</label>
          <input className='invoice-input'
            type="text"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
            readOnly={!editMode.bankName}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('bankName')}></i>
        </div>
        <div className="form-group">
          <label>Bank IFSC Code</label>
          <input className='invoice-input'
            type="text"
            name="bankIfscCode"
            value={formData.bankIfscCode}
            onChange={handleChange}
            readOnly={!editMode.bankIfscCode}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('bankIfscCode')}></i>
        </div>
        <div className="form-group">
          <label>Account Type</label>
          <input className='invoice-input'
            type="text"
            name="accountType"
            value={formData.accountType}
            onChange={handleChange}
            readOnly={!editMode.accountType}
          />
          <i className="fas fa-edit" onClick={() => handleEditClick('accountType')}></i>
        </div>
        <button type="submit" className="update-button" disabled={isUpdating}>
          {isUpdating ? 'Updating...' : 'Update'}
        </button>
      </form>
    </div>
  );
};

export default EditInvoice;
