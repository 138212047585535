import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Images/company-logo.png";
import loginImage from "../../Images/payroll-login-bg-image.png";
import Toast from "../../utils/toast";
import "./index.css";

const Login = () => {
  const [loginDetails, setFormData] = useState({
    email: "",
    password: "",
  });

  const [buttonLoader, setButtonLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...loginDetails, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}admin/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginDetails),
        }
      );

      if (response.ok) {
        setButtonLoader(false);
        const data = await response.json();

        // Save JWT token in cookies
        Cookies.set('jwt', data.token, { expires: 1 }); // The token will expire in 1 day

        setFormData({
          email: "",
          password: "",
        });

        Toast.fire({
          icon: "success",
          title: data.message,
        });

        navigate("/users");
      } else {
        setButtonLoader(false);
        const data = await response.json();
        Toast.fire({
          icon: "error",
          title: data.message,
        });
      }
    } catch (error) {
      setButtonLoader(false);
      console.log(error);
    }
  };

  return (
    <div className="customer-login-main-container">
      <div className="customer-login-sub-container">
        <div className="customer-login-right-container">
          <div className="customer-login-logo-container">
            <img src={logo} alt="" className="w-full" />
          </div>
          <h1 className="app-main-heading-3 my-5 !text-purple-800">Login</h1>

          <form
            className="customer-login-form-container"
            onSubmit={handleSubmit}
          >
            <div>
              <label className="app-para-heading-5 m-0 !font-medium">Email</label>
              <input
                type="text"
                placeholder="Enter email"
                name="email"
                value={loginDetails.email}
                className="customer-input-field"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="app-para-heading-5 m-0 !font-medium">Password</label>
              <input
                type="password"
                placeholder="Enter Your Password"
                name="password"
                value={loginDetails.password}
                className="customer-input-field"
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex justify-end">
              <Link to="/forgot-password">
                <p className="app-para-heading-5 cursor-pointer text-">Forgot Password</p>
              </Link>
            </div>
            <button
              type="submit"
              className="main-button"
              onClick={handleSubmit}
            >
              {buttonLoader ? <div className="button-loader"></div> : "Login"}
            </button>
          </form>
        </div>
        <div className="customer-login-left-container">
          <img src={loginImage} alt="" className="customer-login-solar-image" />
        </div>
      </div>
    </div>
  );
};

export default Login;
